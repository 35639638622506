@charset "utf-8";

/* swith */
/* input[type="radio"]:checked + label {
    background-color: #ffffff;
    transition: .5s all ease;
    color: #000;
    border: 1px solid #40bffa;
    transition: all 0.2s ease-in-out 0s;
} */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

:root {
  --theme-color: #ffd21f;
  --title-color: #141d38;
  --body-color: #737887;
  --smoke-color: #f5f5f5;
  --smoke-color2: #e2e8fa;
  --black-color: #000000;
  --black-color2: #080e1c;
  --white-color: #ffffff;
  --light-color: #72849b;
  --success-color: #28a745;
  --error-color: #dc3545;
  --border-color: #e0e0e0;
  --title-font: "Montserrat", sans-serif;
  --body-font: "Montserrat", sans-serif;
  --icon-font: "Font Awesome 6 Pro";
  --main-container: 1220px;
  --container-gutters: 24px;
  --section-space: 120px;
  --section-space-mobile: 80px;
  --section-title-space: 60px;
  --ripple-ani-duration: 5s;
  --primary-opacity: #2f57ef21;
  --secondary-opacity: #b966e721;
  --coral-opacity: #e9967a21;
  --violet-opacity: #80008021;
  --pink-opacity: #db709321;
  --white-opacity: #ffffff21;
  --danger-opacity: #ff000310;
  --warning-opacity: #ff8f3c10;
  --success-opacity: #3eb75e10;
  --black-opacity: rgba(0, 0, 0, 0.04);
  --heading-opacity: #19233550;
  --color-card-1: #fffccf;
  --color-card-2: #ffedff;
  --color-card-3: #ffe8eb;
  --color-card-4: #e9f6ff;
  --color-card-5: #5024d1;
  --color-card-6: #3b3b46;
  --color-success: #3eb75e;
  --color-danger: #ff0003;
  --color-warning: #ff8f3c;
  --color-info: #1ba2db;
  --color-primary-alt: #d1ebe6;
  --color-primary-light: #f1f7ff;
  --color-primary-lighter: #eeeeff;
  --color-secondary-alt: #fef4f4;
  --color-extra: rgb(249, 249, 255);
  --color-extra2: #f9f9ff;
  --color-border: #e6e3f1;
  --color-border-2: #e6e3f14f;
  --color-badge-1: #303f5b;
  --color-badge-2: #f2c94c;
  --color-facebook: #3b5997;
  --color-twitter: #1ba1f2;
  --color-youtube: #ed4141;
  --color-linkedin: #0077b5;
  --color-pinterest: #e60022;
  --color-instagram: #c231a1;
  --color-vimeo: #00adef;
  --color-twitch: #6441a3;
  --color-discord: #7289da;
}
@media(min-width: 600px){
  .m-npo-item {
    background: #ffffff29;
    padding: 20px;
    border-radius: 10px;
    height: 200px;
}
}
.alert-name {
  font-size: 11px;
  line-height: 15px!important;
  font-weight: 500;
  color: red!important;
  margin-top: -20px;
}
.npo-ct-cc .owl-nav {
  position: absolute;
  margin: 0 auto !important;
  left: -55px;
  right: 0;
  width: 110%;
  bottom: 45%;
}

.features-boxs .service-box {
  background: #fff 0 0 no-repeat padding-box;
  border: 3px solid #fff;
  border-radius: 67px;
  box-shadow: 0 0 16px 5px #00000029;
  transition: all .3s linear;
}
a.task-btn{
  font-size: 25px;
  color: #000;
  cursor: pointer;
}
p.no-req {
  margin-top: 21px;
  margin-bottom: 80px;
  font-size: 14px;
  margin-left: -25px;
}

.section-2-main {
  border-radius: 70px;
  box-shadow: 0 0 10px 5px #dedede;
  margin: 0 auto;
  max-width: 1120px;
  padding: 50px 35px;
  text-align: center;
}
.section-2-inner {
  margin-bottom: 50px;
}
.our__screen__conatiners.position-relative.bg-white .item img{
  width: 100%;
  margin: 0 auto;
  height: 600px!important;
}
.our__screen__conatiners.position-relative.bg-white .item:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 50px;
  z-index: 999999;
  background: white;
  bottom: 0px;
}
.section-2-inner h3 {
  font-size: 38px;
  text-align: center;
}
.ngo-check.gym.style4.mb-40.list-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.ngo-check.gym ul img {
  width: 25px;
  margin-right: 15px;
  margin-bottom: -10px!important;
  position: relative;
  bottom: -6px;
}
.vustom-my-bg-grey{
  position: relative;
}
.inner_main.mt-top.npo-inner-section.gym-inner h3 {
  font-size: 16px!important;
  font-weight: 400;
  max-width: 370px;
  margin-bottom: 50px!important;
}
img.custom-workflow {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.inner_main.mt-top.npo-inner-section.gym-inner .last-ngo-desc {
  max-width: 800px;
  margin: 30px auto 0px;
  text-align: center;
}
.vustom-my-bg-grey:before {
  background: #F5F5F5;
  content: '';
  position: absolute;
  width: 100%;
  height: 600px;
  z-index: -1;
  top: 0px;
}
div.oil-section {
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url('../src/assets/images/webmail/oil-bg.svg');
  background-position: center bottom;
  background-size: inherit;
}
div.oil-section.npo {
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(rgba(255, 255, 255, 0.559),rgba(255, 255, 255, 0.652)),url('../src/assets/images/webmail/donation.jpg')!important;
  background-position: center bottom;
  background-size: inherit;
}
div.oil-section.gym-section {
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(rgba(173 173 173 / 50%),rgba(173 173 173 / 50%)) , url('../src/assets/images/webmail/gym-banner.png');
  background-position: center bottom;
  background-size: inherit;
}

.oil-main h1 {
  font-size: 44px;
  margin-bottom: 35px;
}

section.bg-grey,
section.section-2 {
  padding: 70px 0px !important;
}

.custom-owl-slider .custom-logo img {
  width: 200px !important;
}

.custom-owl-slider .owl-stage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
}

.mt-3.pt-0.mt-md-5.row.custom-pt.mx-width {
  max-width: 1200px;
  margin: 0 auto;
}

.oil-main p {
  margin-bottom: 50px;
}

.px-70 {
  padding: 0px 70px;
}

.bg-grey {
  background: #F5F5F5;
}

.custom-oil-title {
  font-weight: 500 !important;
  margin-top: 15px;
  color: #000;
}

.ser-custom-img {
  width: 110px !important;
  height: 110px !important;
}

p.tasktak_textbox-label {
  font-size: 13px;
  line-height: 22px !important;
}

html body .custom-mb-5 {
  margin-bottom: 20px !important;
}

.feature-inner.single-feature {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 45px 30px;
  border-radius: 10px;
  background: #fff;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-box-shadow: 0px -33px 51px rgba(209, 209, 209, 0.16);
  box-shadow: 0px -33px 51px rgba(209, 209, 209, 0.16);
  overflow: hidden;
}

.thumbs img {
  width: 100%;
  margin-top: -5px;
}

.thumbs {
  width: 200px;
  height: 200px;
  display: flex;
  box-shadow: 0px 0px 10px 5px #11063a;
  object-fit: contain;
  background: #171150;
  border-radius: 100px;
  padding: 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.thumbss img {
  width: 100%;
}

.service-box h3.text-second.fs-4 {
  font-size: 17px !important;
  font-weight: bold;
  min-height: 45px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.cust-width.w-50 {
  width: 90% !important;
}

.thank-you h1 {
  color: #fff;
  font-size: 45px;
  font-weight: 900;
  text-align: center;
}

.thank-you {
  height: 100vh;
  overflow: hidden;
  background-color: #11063a;
  padding: 0px !important;
}

.feature-inner.single-feature p {
  text-align: center;
}

.item.webmail .save-icon img {
  width: 85px !important;
  height: 88px;
  margin-bottom: 20px;
}

.global-check li {
  font-size: 16px;
  color: #000;
}

.item.webmail .save-icon {
  display: block;
  text-align: left !important;
}

.item.webmail .save-icon h5 {
  text-align: left !important;
  color: #1B1363;
  font-weight: 600;
}

.item.webmail .save-icon p {
  font-size: 15px;
  line-height: 27px !important;
  font-weight: 300;
  margin-bottom: 0px;
  margin-top: 20px;
}

.item.webmail {
  background: #fff 0 0 no-repeat padding-box;
  min-height: 392px;
  margin-left: 15px;
  border-radius: 45px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 3px 15px #0000001a;
  padding: 28px !important;
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out;
}

.inner_main .h2 {
  font-size: 34px !important;
  line-height: 1.25;
}

.feature-inner.single-feature img {
  width: 65px;
  margin-bottom: 20px;
}

.min-width-para {
  max-width: 700px;
  display: block;
  margin: 20px auto 0px;
}

select.language-change.border-0 {
  outline: none !important;
  color: var(--main-color);
  padding-left: 20px;
}

.inner-banners {
  box-shadow: 0px 0px 5px 5px #32323217;
  margin: 0px 0px 10px;
}

.inner-title.fw-bold {
  font-weight: 500 !important;
  font-size: 50px;
}

.inner-npo-text {
  font-weight: bold;
}

a.col-md-auto.col-5.btn.btn-yellow.border-0.rounded-pill.fs-5.lh-23.px-4.py-2.me-2.me-md-4.inner-btn-ngo {
  font-size: 16px !important;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  align-items: center;
}

.custom-pt {
  padding-top: 30px !important;
}

.inner_main.gray.mt-top.npo-inner-section .container {
  max-width: 1015px !important;
  margin: 0 auto;
}

.inner_main.gray.mt-top.npo-inner-section .col-text {
  padding: 0px;
}

.ngo-check ul img {
  width: 8px!important;
  margin-right: 15px;
}

.ngo-check ul li {
  display: flex;
  align-items: baseline;
  padding: 10px 0px;
}
.min-width-ps{
  max-width: 1000px;
  margin: 0 auto;
}
.save-icon img {
  width: 85px !important;
}

.npo-inner-sections {
  padding: 30px 0px 30px !important;
}

.save-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

p.text-center.save-text.pt-3 {
  font-size: 15px;
  line-height: 16px !important;
  font-weight: 500;
}

.features-boxs .service-box span {
  width: 85px;
  height: 85px;
  text-align: center;
  margin-bottom: 20px !important;
}

.npo-inner-sections .owl-stage-outer {
  padding: 30px 0px !important;
  height: 100% !important;
}

.features-boxs .service-box {
  height: 380px;
  display: flex;
  flex-direction: column;
  padding: 45px 30px !important;
}

.npo-inner-sections .owl-carousel .owl-stage {
  background: #fff !important;
}

.features-boxs h3.text-second.fs-4 {
  height: 55px;
}

.features-boxs .service-box ul li {
  list-style: none !important;
  color: #000;
}

.features-boxs .service-box ul li img {
  width: 7px;
  margin-right: 12px;
}

.features-boxs .service-box ul li {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.features-boxs .service-box span img {
  width: 60px;
  height: 90px;
}

.features-boxs .item.col-md-3 {
  padding: 5px 10px;
}

.min-width-p {
  max-width: 800px;
  margin: 0 auto;
}

.cta-inner-ngo {
  background: #FFD21F;
}

.cta-inner-ngo {
  background: #FFD21F;
  padding: 50px;
  display: flex;
  border-radius: 20px;
  justify-content: center;
}

.cta-inner-ngo .row {
  justify-content: center;
  align-items: center;
}

h3.h2.text-second.fs-4.mb-3.position-relative {
  text-transform: capitalize;
}

.webmail .floating {
  width: auto !important;
}

.language-change .custom-radio {
  position: relative;
  cursor: pointer;
}

.language-change .custom-radio input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-radio label {
  width: 25px;
  height: 25px;
  border: 1px solid #ababab;
  overflow: hidden;
  border-radius: 100px;
  margin: 5px 5px 0px 5px;
  object-fit: cover;

}

.custom-radio img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

form.language-change {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul#sub-menu-ul {
  position: absolute;
  background: #ebebeb;
  padding: 10px;
  margin-left: -10px;
  margin-top: 28px;
  border-radius: 0px 0px 10px 10px;
}

#sub-menu-ul {
  display: none;
}

.drop,
.menu-it {
  position: initial;
}

.menus {
  display: flex;
  list-style: none;
}

.menu-items {
  position: initial;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;

  /* new additions */
  display: flex;
  justify-content: left;
  align-items: center;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
  font-size: 16px;
}

/* new addition */
.menu-items button a {
  padding: 0;
}

.menu-items .dropdown button span {
  margin-left: 10px;
  font-size: 20px;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .nav-item {
  color: var(--main-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.menu-items .dropdown {
  position: absolute;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.menu-items .dropdown.show {
  display: block;
}

.menu-items .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: 15px;
  margin-left: 2px;
  border-radius: 0px;
}

/* new addition */

.arrow.custom {
  display: none;
}

@media (min-width: 960px) {
  .arrow.custom {
    display: block;
  }
}












ul.has-submenu.sm-nowrap {
  position: absolute;
  left: 185px;
  display: none;
  width: 175px;
  background: #dedede;
  top: 10px;
  padding: 10px;
}

.webmail.features-boxs .service-box span {
  width: 100%;
}

.webmail .service-box h3.text-second.fs-4 {
  text-align: center;
}

.webmail .floating {
  width: 46px !important;
  height: 46px !important;
}

/* .inner-banners,
.bg-patter {
  background-image: linear-gradient(
    100.62deg,
    rgba(249, 215, 175, 0.3) 0%,
    rgba(214, 202, 245, 0.3) 24.03%,
    rgba(198, 241, 255, 0.3) 45.73%,
    rgba(248, 242, 222, 0.3) 69.05%,
    rgba(212, 179, 253, 0.3) 100.44%
  );
  z-index: 0;
} */





























.privacy-conatiner h4,
.privacy-conatiner h5,
.privacy-conatiner h6 {
  color: #000;
  font-weight: 600;

}

p {
  color: #000 !important;
}

.privacy-conatiner h4 {
  margin: 30px 0px 25px;
}

.privacy-conatiner .pb-3 {
  margin-bottom: 0px;
}

.main-heading-privacy {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 30px;
}

.v-small-date {
  font-size: 14px !important;
}

.privacy-conatiner p {
  font-size: 16px;
}

.v-small {
  font-size: 12px !important;
}

.privacy-sec {
  padding: 150px 0px 50px;
}

.privacy-inner {
  box-shadow: 0px 0px 10px 5px #c6c6c63d;
  border-radius: 10px;
  padding: 50px 30px;
}

.pricing-form li {
  width: 50%;
}

.pricing-form .nav-link {
  border: none !important;
  width: 100%;
  box-shadow: 0px 0px 3px 5px #eee !important;
  margin: 10px 6px;
}

.pricing-form .nav-tabs .nav-item.show .nav-link,
.pricing-form .nav-tabs .nav-link.active {
  background-color: #FFD21F;
  box-shadow: none !important;
  margin: 10px 0px !important;
}

.pricing-form .input-controls {
  height: 45px;
  border-radius: 100px;
  box-shadow: 0px 0px 2px 4px #e4e1e1b0 !important;
}

.new-login-form-button {
  background: #1b1363;
  display: block;
  width: 180px;
  margin: 30px auto 10px;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  border-radius: 10px;
}

.pricing-form {
  background: #fff;
  box-shadow: 0px 0px 10px 5px #d1d1d1;
  border-radius: 0px 0px 40px 40px;
  overflow: hidden;
}

.pricing-form .tab-content>.active {
  background: #fff;
}











@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

body {
  font-family: 'Open Sans', 'sans-serif';
}

.mega-dropdown {
  position: static !important;
}

.mega-dropdown-menu {
  padding: 20px 0px;
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.mega-dropdown-menu>li>ul {
  padding: 0;
  margin: 0;
}

.mega-dropdown-menu>li>ul>li {
  list-style: none;
}

.mega-dropdown-menu>li>ul>li>a {
  display: block;
  color: #222;
  padding: 3px 5px;
}

.mega-dropdown-menu>li ul>li>a:hover,
.mega-dropdown-menu>li ul>li>a:focus {
  text-decoration: none;
}

.mega-dropdown-menu .dropdown-header {
  font-size: 18px;
  color: #ff3546;
  padding: 5px 60px 5px 5px;
  line-height: 30px;
}

.carousel-control {
  width: 30px;
  height: 30px;
  top: -35px;

}

ul.dropdown-menu.fullwidth .tab-content>.active {
  display: block;
  background: #fafafa;
  padding: 0px !important;
  border-radius: 0px !important;
}

ul.dropdown-menu.fullwidth ul.mb-3.navbar-nav.nav-tabs {
  flex-direction: column;
  width: 25%;
  margin: 0px !important;
}

ul.dropdown-menu.fullwidth.openss {
  flex-direction: row;
  display: flex !important;
}

ul.dropdown-menu.fullwidth {
  display: none;
}

ul.dropdown-menu.fullwidth button.nav-link {
  width: 100%;
  margin: 0px 0px 10px;
  text-align: left;
  padding: 20px 10px;
}

ul.dropdown-menu.fullwidth button.nav-link.active {
  background-color: #141d38;
  color: #fff;
}

ul.dropdown-menu.fullwidth.openss {
  display: block;
  opacity: 1;
  transition: all 1s ease-in;
}

ul.dropdown-menu.fullwidth {
  margin-top: 20px !important;
  opacity: 0;
  transition: all 1s ease-in;
}

.left.carousel-control {
  right: 30px;
  left: inherit;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  font-size: 12px;
  background-color: #fff;
  line-height: 30px;
  text-shadow: none;
  color: #333;
  border: 1px solid #ddd;
}



































.features-box .service-box {
  width: 250px;
}

@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }

  .npo-ct-cc .owl-nav {
    display: none;
  }
  body li.dropdown.w3_megamenu-fw {
    display: none !important;
  }

  img.about-image {
    width: 100% !important;
  }
}

img.img-fluid.pt-0.mt-4.mt-md-5.banner-images-inr {
  margin-top: 0px !important;
}

/* ----------new home-page-design---------------- */
nav.navbar.mb-0.pt-3.pb-3.navbar.navbar-expand-lg.navbar-light {
  background: #fff;
  padding: 0px !important;
  box-shadow: 0px 3px 6px #00000029;
}

p.text-white.d-md-block.medium.text-center.col-12.col-md-9.mx-auto.px-md-5 {
  margin-top: 30px;
}

p.text-dark.m-0 {
  font-size: 14px !important;
}

.nav-link.active {
  position: relative;
}

th.bg-color.bt-radius {
  background: #e4ebef;
  padding: 15px 0px;
}

span.task {
  color: #1e1386;
}

span.tak {
  color: #ffd21f;
}

.bg-color {
  text-align: center;
  position: relative;
}

.bg-color {
  text-align: center;
  background: #f6f6f6;
}

td.bg-color:before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: -25px;
  height: 45px;
  background: #f6f6f6;
  z-index: -99;
}

li {
  list-style: none;
  overflow: hidden;
  position: relative;
}

.features-box .service-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #0000001a;
  border-radius: 45px;
  padding: 25px !important;
}

.features-box .service-box {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
}

span.navbar-brand a img {
  width: 150px;
  object-fit: contain;
}

.features-box .service-box:hover {
  transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  margin: -2px -5px 0px -5px;
  background: #1b1363e6 0% 0% no-repeat padding-box;
}

.service-box ul li {
  list-style: inside !important;
  color: #000;
}

.features-box .service-box:hover a.text-decoration-none {
  background: #ffffff;
  color: #6396c9;
}

#service .owl-carousel.owl-theme.owl-loaded.owl-drag {
  padding: 20px 0px 85px;
  margin-bottom: -95px;
}

#service .owl-stage-outer {
  padding: 25px 0px 0px 30px;
}

.left-image img {
  width: 400px;
  margin-left: -50px;
  margin-right: -120px;
}

.features-box .service-box:hover h3,
.features-box .service-box:hover li {
  color: #fff;
}

.features-box .service-box:hover span img {
  filter: invert(1);
}

.footer-cta-wrapper {
  height: 400px;
  background: #fff;
  box-shadow: 0px 0px 10px 5px #0000001a;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-top-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #00000029;
  padding: 50px 0px 70px;
  margin-top: 150px;
}

.footer-cta-wrapper {
  height: 500px;
  z-index: 99;
  position: relative;
  background: #fff;
  box-shadow: 0px 0px 10px 5px #0000001a;
  padding: 90px 40px 50px;
  display: flex;
  justify-content: space-around;
  margin-top: -145px;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}

.contactForm .form-control {
  border: 1px solid #eee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 10px;
}

.footer-cta-description h3 {
  font-size: 47px;
}

.footer-btn {
  width: 100%;
  text-align: right;
}

.btna .btnss {
  background: transparent linear-gradient(270deg, #0092d6 0%, #40bffa 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 26px;
  color: #fff;
}

ul.ft-link.ft-cta li {
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
}

ul.ft-link.ft-cta li a {
  font-size: 18px;
  color: #1b1363;
  font-weight: 500;
  letter-spacing: 1px;
}

ul.ft-link.ft-cta li span {
  font-size: 16px;
  color: #000000;
}

ul.ft-link.ft-cta li p {
  margin: 0px;
  font-size: 20px;
  color: #1b1363;
  font-weight: 600;
  letter-spacing: 1px;
}

ul.ft-link.ft-cta li span {
  font-size: 16px;
  color: #7f7f7f;
}

.cta-right {
  padding: 0px 45px;
}

ul.ft-link.ft-cta li.d-block a {
  width: 35px;
  height: 35px;
  padding: 5px 12px;
  background: #1b1363;
  border-radius: 10px;
  font-size: 15px;
  color: #fff;
  margin: 0px 10px 0px 0px;
}

span.task-btns {
  color: #000;
  cursor: pointer;
  font-size: 25px;
  font-weight: 500;
}

.custom-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.custom-box {
  width: 30%;
  margin-right: 25px;
  background: #ffd21f63 0% 0% no-repeat padding-box;
  border-radius: 100px;
  padding: 25px;
}

.counter-number span {
  font-size: 60px;
  font-weight: 600;
  font-family: "Montserrat";
}

.counter-wrapper {
  text-align: center;
}

.counter-section {
  padding: 70px 0px;
}

.service-box a.text-decoration-none {
  background: #1b1363;
  display: block;
  width: 150px;
  margin: 30px auto 10px;
  color: #fff;
  text-align: center;
  padding: 6px 20px;
  border-radius: 10px;
}

.service-box h3.text-second.fs-4 {
  font-size: 17px !important;
  font-weight: bold;
}

.footer-top-wrapper {
  position: relative;
}

.footer-top-wrapper img {
  width: 100%;
  position: absolute;
}

.footer-top-wrapper img {
  position: absolute;
  width: 100%;
  height: 100%;
}

img.footer-img-right {
  bottom: 0px;
  right: 0px;
}

img.footer-img-left {
  left: 0px;
  top: 0px;
}

/* ----------new home-page-design end---------------- */

#tabss .nav-pills,
#tabss .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  font: 600 18px/1.4 Axiforma, Arial, serif;
  color: #b9bec7;
}

#tabss .nav-link.active {
  color: #000;
  background: url(../src/assets/images/dots.svg) repeat center/auto;
  position: relative;
}

.tab-content>.active {
  display: block;
  background: linear-gradient(270deg, #ffd21f8f 0, #ffc10738 100%),
    url(../src/assets/images/dots.svg) repeat center/auto;
  padding: 50px 40px;
  border-radius: 20px 20px 0px 0px;
}

.feature-downs {
  width: 15px !important;
  rotate: 270deg;
  margin-left: 8px;
  margin-top: -2px;
}

.feature-downs {
  width: 30px !important;
}

button.cs-btns.bg-white.border-0.pl-0 {
  padding-left: 0px;
}

button.cs-btns.bg-white.border-0.pl-0 {
  padding-left: 0px;
  font-weight: 700;
}

.tabs-footer-inner a {
  font-weight: 800;
  font-size: 20px;
  letter-spacing: 1px;
  color: #000;
}

img.feature-downs {
  width: 20px;
}

.tabs-footer {
  background: linear-gradient(270deg, #ffd21fa8 0, #ffc10775 100%);
  padding: 30px 0px;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

#tabss .flex-column.nav.nav-pills .nav-item {
  display: flex;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
}

.tabs-description {
  text-align: center;
  margin-top: 40px;
}

.tabs-description p {
  font-size: 18px;
  color: #000;
  text-align: left;
  padding-right: 50px;
}

.tabs-image {
  width: 100%;
}

.tabs-image img {
  width: 100%;
}

section#tabss {
  padding-top: 60px !important;
}

#tabss .nav-link {
  padding: 20px 15px 20px 15px;
}

#tabss .nav-link.active:before {
  background: linear-gradient(270deg, #ffd21f 0, #ffc107 100%);
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 5px;
  border-radius: 15px;
  left: 2px;
  bottom: 0;
}

html body {
  font-family: Montserrat !important;
}

h6.text-orange.text-center.test-top {
  color: #1b1363;
}

@media(max-width: 1200px) {
  h1.fw-bold.display-4.mb-3.text-main-heading {
    font-size: 30px !important;
  }

}

@media (min-width: 1200px) {
  p {
    font-size: 18px;
    line-height: 27px !important;
  }

  #service .owl-dots {
    margin-left: -450px;
  }

  p.col-md-auto.mb-0.text-orange.mt-3.mt-md-2 {
    margin-top: 20px !important;

    font-weight: 500;
  }

  h5.fs-5.lh-23.mb-0.text-orange {
    font-size: 17px !important;
    font-weight: 700;
    margin-bottom: 15px !important;
    margin-top: 55px;
  }

  #home p.text-gray.lh-19.mb-4.pb-2 {

    font-weight: 500;
    padding-right: 55px;
    line-height: 22px !important;
  }

  a.col-md-auto.col-5.btn.btn-yellow.border-0.rounded-pill.fs-5.lh-23.px-4.py-2.me-2.me-md-4 {
    width: 235px;
    height: 60px;
    font-size: 23px !important;
    display: flex;
    justify-content: center;
    font-weight: 600;
    align-items: center;
  }

  .custom-about {
    font: normal normal bold 40px/45px Montserrat !important;
    color: #000;
  }

  .logo-text {
    font: normal normal 500 40px/45px Montserrat !important;
    color: #000;
  }

  .test-top {
    font: normal normal 500 20px/48px Montserrat;
  }

  h1.fw-bold.display-4.mb-3.text-main-heading {
    font-size: 45px;
    font-weight: 600 !important;
    line-height: 53px;
    color: #000 !important;
  }
}

ul.global-check li {
  padding-bottom: 13px;
}

ul.global-check {
  padding-bottom: 0px !important;
  margin-bottom: -15px;
}

.item.item-logo-img img {
  height: 60px;
  object-fit: contain;
  background-color: #ffffff;
  background-blend-mode: multiply !important;
  filter: grayscale(1);
}

.no-credit-req {
  clear: both;
  text-align: end;
  margin-bottom: -30px;
  padding-top: 20px;
  font-size: 16px;
  padding-right: 27px;
}

.nowwrap {
  white-space: nowrap;
}

div#price_4 .price-box span {
  opacity: 0;
}

.custom-save-img {
  width: 85px;
  height: 85px;
}

.custom-owl-nav .owl-nav {
  position: absolute;
  margin: 0 !important;
  left: -65px;
  right: 0;
  width: 109%;
  bottom: 50%;
}

#tabss .inner_main {
  background: url(../src/assets/images/dots.svg) repeat center/auto,
    linear-gradient(270deg, #ff9ec9 0, #fd71af 100%);
  box-shadow: 0 25px 45px rgb(253 113 175 / 50%);
}

.col-text p {
  font-size: 18px;
}

.checklist.style4.mb-40.list-center .cs-btn,
.checklist.style4.mb-40.list-center .gray {
  text-align: left;
  display: block !important;
  margin: 0px;
}

.checklist.style4.mb-40.list-center .cs-btn {
  background: #1B1363 0% 0% no-repeat padding-box !important;
  border: none !important;
  outline: none !important;
}

button,
.btn {
  outline: none !important;
}

.service-images,
.about-image {
  width: 460px;
}

@media (max-width: 765px) {
  .counter-section {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .our__screen__conatiners.position-relative.bg-white .item img{
    height: auto!important;
  }
  .our__screen__conatiners.position-relative.bg-white .item:after{
    height: 0px!important;
  }
  p.no-req {
    margin-top: 21px;
    margin-bottom: 80px;
    font-size: 14px;
    margin-left: 0px!important;
}

  .section-2-border div li {
    padding: 10px 0px;
  }

  .mt-3.pt-0.mt-md-5.row.custom-pt.mx-width {
    margin-top: 45px !important;
  }

  .section-2-border div {
    display: block !important;
    text-align: left;
    padding: 0px;
  }

  .thumbs {
    width: 130px !important;
    height: 130px !important;
  }

  .my-margin-none {
    margin: 10px 0px !important;
  }

  .thankyou-title {
    font-size: 25px !important;
  }

  p.text-light.w-50 {
    width: 100% !important;
    font-size: 13px;
  }

  html body .review-img.col-6 img {
    width: 100% !important;
    height: 100% !important;
  }

  html body .my-owol .owl-dots {
    position: inherit !important;
    top: auto !important;
    margin-left: 0px !important;
    z-index: -1;
  }

  .review-icon h3 {
    font-size: 30px !important;
  }

  .item.row.justify-content-center.align-items-center.custom-reviewss .col-6 .img-before img {
    width: 135px !important;
    height: 135px !important;
    margin-bottom: 50px;
    border-radius: 100px;
  }

  .item.row.justify-content-center.align-items-center.custom-reviewss .review-img.col-6 .img-before:after {
    content: none !important;
  }

  ul.ft-link li {
    word-break: break-all !important;
    white-space: nowrap;
  }

  .item.row.justify-content-center.align-items-center.custom-reviewss .col-6 {
    width: 100%;
  }

  .item.row.justify-content-center.align-items-center.custom-reviewss {
    display: block;
  }

  .justify-content-end.navbar-collapse.collapse.show {
    height: 100vh;
    display: flex;
    justify-content: start !important;
    align-items: center;
    flex-direction: column;
    padding-top: 60px;
  }

  .justify-content-end.navbar-collapse.collapsing {
    height: 100vh;
    display: flex;
    justify-content: start !important;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
  }

  .navbar-light .navbar-nav .nav-link,
  .navbar-light .nav-item,
  .navbar-light .navbar-nav select {
    font-size: 25px;
    text-align: center;
    font-weight: 700;
  }

  .counter-client p {
    color: #fff !important;
  }

  .custom-pt {
    padding-top: 10px !important;
  }

  img.img-fluid.pt-0.mt-4.mt-md-5.banner-images-inr {
    margin-top: 30px !important;
  }

  .ngo-check ul li {
    text-align: left;
  }

  h1.inner-title.fw-bold.display-4.mb-3.aos-init.aos-animate {
    font-size: 25px;
  }

  h3.text-second.fs-4.mb-3.position-relative.h2,
  h2.text-second.fs-4.mb-3.position-relative.h2 {
    font-size: 22px !important;
  }

  .footer-cta-wrapper {
    margin-bottom: 40px;
  }

  section#about .px-5,
  section#feature .px-5 {
    padding: 20px !important;
  }

  section.features-mains {
    padding: 0px 10px !important;
  }

  .custom-item {
    padding: 20px 0px !important;
  }

  .cta-right {
    padding: 0px 15px !important;
  }

  h2 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 0px !important;
    padding-bottom: 20px !important;
  }

  #about h2.fw-medium.lh-sm.mb-5 {
    margin-bottom: 0px !important;
    padding-top: 30px;
  }

  .about-main .lh-19 {
    line-height: 22px !important;
    text-align: center;
  }

  body .about-main {
    padding-bottom: 0px !important;
  }

  .counter-wrapper {
    text-align: center;
    display: flex;
    width: 150px;
    justify-content: center;
    background: #1b1363;
    color: #fff;
    flex-direction: column;
    align-items: center;
    border-radius: 100%;
    margin: 10px auto;
    height: 150px;
  }

  .counter-client p {
    margin: 0px !important;
  }

  .footer-cta-wrapper {
    padding: 90px 20px 50px !important;
  }

  .left-image img {
    width: 375px;
    margin-left: -310px;
    margin-right: -90px;
  }
}

img.img-fluid.pt-0.mt-4.mt-md-5 {
  margin-top: -100px !important;
}

section#about .container {
  max-width: 1015px;
}

body .about-main {
  padding-bottom: 0px !important;
}

.ft-hide-custom {
  opacity: 0;
}

.section-footer-top {
  padding: 20px 0px 20px;
}

div#section-footer {
  border-top: 2px solid #eee;
}

h5.ft-title {
  color: #1b1363;
}

.footer-widget .ft-link li {
  margin: 0;
  font-size: 16px;
  line-height: 25px;
  list-style: inside !important;
}

.footer-widget .ft-link li::marker {
  color: #ffd21f;
}

.footer-widget .ft-link li a {
  color: #c0c0c0;
}

a#cus-hi,
a#cus-his {
  color: #000;
}

/* ----------testimonials--------- */
.testimonials {
  padding: 80px 0px;
}

.testimonials-wrapper {
  background: #fff;
  box-shadow: 0 25px 75px rgb(16 30 54 / 25%);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 100px;
  margin-top: 50px;
}

.testimonials-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  border-top: 1px solid #e9ebf0;
  width: 100%;
}

.testimonials-header img {
  width: 120px;
  height: 120px;
}

.testimonials-header {
  position: relative;
  top: -65px;
  margin-bottom: -55px;
  width: 120px;
  display: flex;
  justify-content: center;
  height: 120px;
  background: #40bffa;
  align-items: center;
  border-radius: 100%;
  box-shadow: 0px 0px 5px 5px #e7e7e7;
  border: 5px solid #fff;
  overflow: hidden;
}

.testimonials-title h5 {
  color: #7c828d;
  font: 800 25px/1.2 Axiforma, Arial, serif;
  margin-bottom: 20px;
}

.testimonials-header h5 {
  color: #ffffff;
  font: 800 25px Axiforma, Arial, serif;
  margin-bottom: 0px;
}

.testimonials-title p {
  color: #333;
  font: 400 20px/35px Axiforma, Arial, serif;
  font-style: italic;
}

.testimonials-title {
  padding: 30px 40px;
}

.we-love {
  text-align: center;
  margin: 30px 0px 70px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.we-love img {
  width: 40px;
  margin-top: -5px;
}

.get-qoute-banner .info h6 {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.testimonials.style-7 .get-qoute-banner .img {
  text-align: center;
}

.get-qoute-banner .img img {
  width: 200px;
  margin-top: -30px;
}

.butn.btn-icon-circle,
.btn.btn-icon-circle {
  position: relative;
  padding-right: 50px;
  text-transform: capitalize;
}

.butn small,
.btn small {
  font-size: 15px;
  position: relative;
  z-index: 2;
  text-transform: capitalize;
}

.butn.btn-icon-circle i,
.btn.btn-icon-circle i {
  position: absolute;
  right: -43px;
  bottom: -5px;
  width: 30px;
  height: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
  border-radius: 50%;
  font-size: 16px;
}

.get-qoute-banner .info p {
  color: #fff;
  margin-bottom: 5px;
}

.get-qoute-banner {
  border-radius: 30px 30px 0px 0px;
  background-image: linear-gradient(to right,
      #501e9c 0%,
      #8169f1 30%,
      #8169f1 30%,
      #a44cee 73%,
      #ff847f 100%);
  padding: 0 50px;
  margin-top: 130px;
}

.btn-11 {
  position: relative;
  color: #000000;
  width: 200px;
}

.btn-11:before,
.btn-11:after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #199dda;
  border-radius: 50%;
}

@-webkit-keyframes criss-cross-left {
  0% {
    left: -20px;
  }

  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes criss-cross-left {
  0% {
    left: -20px;
  }

  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@-webkit-keyframes criss-cross-right {
  0% {
    right: -20px;
  }

  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes criss-cross-right {
  0% {
    right: -20px;
  }

  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}

[class^="btn-"] {
  position: relative;
  overflow: hidden;
}

.btnss {
  padding: 12px 16px;
  cursor: pointer;
  border-width: 1px;
  width: 200px;
  background: #fff;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 12%);
  position: relative;
  transition: 0.3s;
}

.btn-11:before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.btn-11:after {
  right: -20px;
  transform: translate(50%, -50%);
}

.btn-11:hover {
  color: #000;
}

.btn-11:hover:before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.btn-11:hover:after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.footer-style-1 .footer-top {
  padding-top: 35px;
  padding-bottom: 90px;
}

.footer-style-1 .description {
  font-size: 16px;
  line-height: 25px;
}

.footer-style-1 .newsletter-form .right-icon {
  display: block;
}

.footer-style-1 .form-group {
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
  display: block;
}

.footer-style-1 .form-group {
  margin-bottom: 20px;
  display: inline-block;
}

.mb--20 {
  margin-bottom: 20px !important;
}

.footer-widget .form-group label {
  font-size: 16px;
  line-height: 31px;
}

.footer-style-1 .form-group label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 9px;
  color: var(--color-body);
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
  font-size: 18px;
  line-height: 28px;
}

.footer-style-1 .newsletter-form .right-icon input {
  padding-right: 30px;
}

.footer-style-1 .newsletter-form input {
  min-width: 100%;
}

.footer-style-1 .form-group input,
.footer-style-1 .form-group textarea {
  border: 0;
  border-bottom: 2px solid var(--color-border);
  background-color: transparent;
  padding-bottom: 8px;
  padding-top: 20px;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.footer-style-1 .newsletter-form input {
  flex-grow: 1;
  width: auto;
  margin-right: 7px;
  height: 45px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 500px;
}

.footer-style-1 .newsletter-form .right-icon.icon-email::after {
  content: "\e98a";
}

.footer-style-1 .newsletter-form .right-icon::after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: "feather" !important;
}

.task-btn.btn-sm {
  padding: 0 22px;
  font-size: var(--font-size-b3);
  height: 45px;
  line-height: 43px;
}

.task-btn.task-switch-btn {
  overflow: hidden;
}

.task-btn.btn-gradient {
  color: var(--color-white);
  background-size: 300% 100%;
  background-image: linear-gradient(to right,
      var(--color-primary),
      var(--color-secondary),
      var(--color-secondary),
      var(--color-primary));
}

.task-btn.task-switch-btn span {
  display: inline-flex;
  position: relative;
  z-index: 3;
  transition: opacity 0.65s, transform 0.85s;
  transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
}

li {
  list-style: none;
}

.task-btn {
  padding: 0 26px;
  background: var(--color-primary);
  height: 60px;
  line-height: 60px;
  color: var(--color-white);
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  z-index: 1;
  transition: all 0.4s ease-in-out;
  border-radius: 6px;
  border: 0 none;
  outline: none;
}

.copyright-style-1 .copyright-link {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  margin-top: -5px;
  margin-bottom: -5px;
}

.copyright-style-1 .copyright-link li {
  font-size: 16px;
  line-height: 25px;
  padding: 10px 10px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
}

a.f-subs {
  background: transparent linear-gradient(270deg, #1b1363 0%, #3b2cc7 100%) 0% 0% no-repeat padding-box;
  border-radius: 26px;
  color: #fff !important;
  padding: 10px 20px !important;
}

.task-link-hover a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s;
}

.copyright-style-1 .copyright-link li+li::after {
  position: absolute;
  content: "";
  height: 14px;
  width: 2px;
  background: #c3c3c3;
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
}

.mt--30 {
  margin-top: 30px !important;
}

.mt--20 {
  margin-top: 20px !important;
}

.social-default li a:hover::after {
  transform: scale(1);
  opacity: 1;
}

.task-btn.task-switch-btn span {
  display: inline-flex;
  position: relative;
  z-index: 3;
  transition: opacity 0.65s, transform 0.85s;
  transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
}

.task-btn.btn-gradient:hover {
  background-color: inherit;
  background-position: 102% 0;
  transition: all 0.4s ease-in-out;
}

.task-btn.btn-sm {
  padding: 0 22px;
  font-size: var(--font-size-b3);
  height: 45px;
  line-height: 43px;
}

.task-btn:hover {
  background-color: var(--color-secondary);
  color: var(--color-white);
  transform: translate3d(0, -2px, 0);
  box-shadow: var(--shadow-7);
}

.task-btn:hover.task-switch-btn span::after {
  opacity: 1;
  transform: translate(100%, -50%);
}

.task-btn:hover.task-switch-btn span {
  transform: translateX(-200%);
}

.copyright-style-1 .copyright-link li a {
  color: var(--color-body);
  transition: 0.4s;
}

.logo a {
  display: block;
}

.logo a img {
  max-height: 50px;
  object-fit: cover;
}

.footer-style-1 .description {
  font-size: 16px;
  line-height: 25px;
}

.social-default {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: center;
  margin: -5px;
}

.social-default li {
  margin: 5px 10px 0px 0px;
}

.social-default li a {
  width: 48px;
  background-color: var(--color-gray-light);
  height: 48px;
  line-height: 45px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  transition: 0.3s;
  color: var(--color-body);
  z-index: 2;
  position: relative;
}

.social-default li a i {
  vertical-align: middle;
}

.footer-widget .ft-link li {
  margin: 0;
  color: var(--color-body);
  font-size: 16px;
  line-height: 25px;
}

.footer-widget .ft-link li a {
  color: var(--color-body);
  display: inline-block;
  position: relative;
  padding: 5px 0px;
}

.cus-cursor {
  padding: 5px 0px;
}

.footer-widget .ft-link li a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s;
}

hr {
  background-color: var(--color-border);
  opacity: 1;
}

.task-separator::after {
  position: absolute;
  content: "";
  height: 20px;
  width: 1px;
  background: var(--color-border);
  top: 50%;
  transform: translateY(-50%);
}

.ptb--20 {
  padding: 20px 0 !important;
}

.copyright-style-1 p {
  color: var(--color-body);
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 0px;
}

.task-link-hover a {
  position: relative;
}

.process-card .box-title {
  font-weight: 600;
  margin-bottom: 14px;
}

.box-title {
  font-size: 24px;
  line-height: 1.417;
  font-weight: 700;
  margin-top: -0.32em;
}

.process-card_text {
  margin-bottom: -0.53em;
  font-size: 14px;
}

.inner-home p {
  font-size: 13px;
  font-weight: 500;
  color: #0c0303;
}

/* .about-main .text-gray {
  color: #2d2d2d;
} */

.about-main .lh-19 {
  line-height: 27px;
}

.process-card_number:after,
.process-card .pulse:after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.inner-home img {
  width: 100%;
  height: 50px;
}

.inner-home {
  position: relative;
  box-shadow: 0px 10px 15px rgb(8 14 28 / 6%);
  border-radius: 10px;
  background-color: var(--white-color);
  text-align: center;
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  align-items: center;
  flex-direction: column;
}

.title-area {
  margin-bottom: calc(var(--section-title-space) - 10px);
  position: relative;
  z-index: 2;
}

.shadow-title {
  font-family: var(--title-font);
  font-size: 74px;
  font-weight: 900;
  line-height: 1;
  background-image: -webkit-linear-gradient(top,
      rgba(226, 232, 250, 0.7) 0%,
      rgba(226, 232, 250, 0) 88.54%);
  background-image: linear-gradient(180deg,
      rgba(226, 232, 250, 0.7) 0%,
      rgba(226, 232, 250, 0) 88.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: -0.55em 0 -0.45em -0.25em;
}

.sub-title:has(img) {
  margin-top: 0;
}

.sub-title {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-color);
  margin-bottom: 30px;
  text-transform: uppercase;
  line-height: 24px;
  margin-top: -0.34em;
}

.sub-title img {
  margin: 0 10px 0 0;
}

.title-area .sec-title {
  margin-bottom: 15px;
}

.sec-title {
  margin-bottom: calc(var(--section-title-space) - 10px);
  margin-top: -0.32em;
  text-transform: capitalize;
}

.text-theme {
  color: var(--theme-color) !important;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
}

@-webkit-keyframes ripple2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(2.8);
    transform: scale(2.8);
    opacity: 0;
  }
}

@keyframes ripple2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: scale(2.8);
    transform: scale(2.8);
    opacity: 0;
  }
}

.process-card-area {
  position: relative;
}

.process-card-area .process-line {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.gy-40 {
  --bs-gutter-y: 40px;
}

.process-card-wrap {
  padding: 30px 0 0 30px;
}

.process-card {
  position: relative;
  box-shadow: 0px 10px 15px rgb(8 14 28 / 6%);
  border-radius: 10px;
  background-color: var(--white-color);
  text-align: center;
  padding: 30px 20px;
  max-width: 230px;
  margin-left: auto;
  margin-right: auto;
}

.process-card_number,
.process-card .pulse {
  height: 60px;
  width: 60px;
  line-height: 60px;
  background-color: var(--theme-color);
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: -30px;
  left: -30px;
  z-index: 2;
  font-size: 36px;
  font-weight: 700;
  color: var(--white-color);
}

.process-card_number:after,
.process-card_number:before,
.process-card .pulse:after,
.process-card .pulse:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--theme-color);
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.ripple-animation,
.play-btn:after,
.play-btn:before,
.testi-box_quote:after,
.testi-box_quote:before,
.testi-grid_quote:after,
.testi-grid_quote:before,
.service-grid_icon:after,
.service-grid_icon:before,
.process-card_number:after,
.process-card_number:before,
.process-card .pulse:after,
.process-card .pulse:before {
  -webkit-animation-duration: var(--ripple-ani-duration);
  animation-duration: var(--ripple-ani-duration);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: ripple;
  animation-name: ripple;
}

.process-card_number:after,
.process-card .pulse:after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

#process-sec {
  background-image: url("./assets/images/process_bg_1.png");
  padding-bottom: 95px;
}

.process-card_number:after,
.process-card_number:before,
.process-card .pulse:after,
.process-card .pulse:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--theme-color);
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

.process-card_icon {
  margin-bottom: 24px;
}

.btn-group:has(.call-btn) {
  gap: 50px;
}

.main-para {
  line-height: 27px;
  letter-spacing: 0.2px;
  color: #737887;
}

.checklist li img {
  width: 20px;
}

.checklist ol,
ul {
  padding-left: 0rem;
}

p {
  font-family: var(--body-font);
  margin: 0 0 18px 0;
  color: #737887;
  line-height: 1.75;
}

.h2 {
  font-size: 40px !important;
  line-height: 1.25;
}

.checklist li {
  color: #000;
  font-weight: 500;
  margin-top: 10px;
  position: relative;
  width: 50%;
  float: left;
  display: flex;
  align-items: center;
  gap: 10px;
}

.hero-subtitle {
  color: #40bffa;
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 17px;
  text-transform: uppercase;
  margin-top: -0.24em;
}

.as-btn {
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  border: none;
  text-transform: capitalize;
  text-align: center;
  background-color: var(--orange-color);
  color: #fff !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 20.5px 20px;
  min-width: 144px;
  border-radius: 4px;
  letter-spacing: 0.7px;
}

.call-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}

.play-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.process-card_number,
.process-card .pulse {
  height: 60px;
  width: 60px;
  line-height: 60px;
  background-color: var(--theme-color);
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: -30px;
  left: -30px;
  z-index: 2;
  font-size: 36px;
  font-weight: 700;
  color: var(--white-color);
}

.process-card_number:after,
.process-card_number:before,
.process-card .pulse:after,
.process-card .pulse:before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--theme-color);
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  -webkit-animation-name: ripple2;
  animation-name: ripple2;
}

element.style {
  color: rgb(255, 255, 255);
}

.fw-medium {
  font-weight: 700 !important;
}

a.col-md-auto.col-5.btn.btn-yellow.border-0.rounded-pill.fs-5.lh-23.px-4.py-2.me-2.me-md-4 {
  width: 235px;
  height: 60px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  align-items: center;
}

@media (max-width: 500px) {
  .footer-widget .ft-link li a {
    font-size: 14px !important;
  }

  .features-box .service-box {
    width: 275px;
  }

  .counter-number span {
    font-size: 30px !important;
    font-weight: 600;
    font-family: "Montserrat";
  }
}

.service-box.mb-4.p-3 {
  border: none !important;
}

@media (max-width: 766px) {
  body .features-main .container {
    padding: 20px;
  }

  .service-images,
  .about-image {
    width: 100%;
  }

  .f-col-order {
    order: 1;
  }

  p.task-link-hover.text-center.text-lg-start {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .copyright-area .d-flex.justify-content-between.align-items-center {
    flex-direction: column-reverse;
  }

  .copyright-style-1 .copyright-link li+li::after {
    content: none !important;
    display: none !important;
    width: 0px !important;
  }

  a.col-md-auto.col-5.btn.btn-yellow.border-0.rounded-pill.fs-5.lh-23.px-4.py-2.me-2.me-md-4 {
    padding: 17px 0px !important;
    margin: 0 auto;
  }

  .service-box.mb-4.p-3 {
    border: none !important;
  }

  .mb-center {
    text-align: center;
  }

  img.img-fluid.pt-0.mt-4.mt-md-5 {
    margin-top: 10px !important;
  }

  footer.container .task-footer.footer-style-1.bg-color-white.overflow-hidden {
    padding: 10px 30px;
    border-radius: 0px;
  }

  body .feature-add {
    width: 100% !important;
  }

  .get-qoute-banner {
    padding: 30px 50px;
    margin-top: 130px;
    text-align: center;
  }

  .checklist ol,
  .checklist ul {
    padding-left: 0rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .checklist ul li {
    margin-left: 10px;
  }

  body .banner-image {
    width: 100%;
    height: 100%;
  }

  body .col-image img {
    height: 100%;
  }

  body .features-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .col-lg-3 {
    margin-top: 20px;
  }

  body section,
  body #pricing {
    padding-top: 30px !important;
  }

  body .inner-banners,
  body .bg-patter {
    padding-bottom: 30px;
  }

  body .features-heading h2 {
    font-size: 20px;
    margin-top: 20px;
  }

  body .col-text,
  body .col-image {
    padding: 10px;
    text-align: center !important;
  }

  body .mb-reverts .mb-revert {
    flex-direction: column-reverse !important;
  }

  body .inner_main {
    padding: 20px 0px;
  }
}

@media (max-width: 1024px) {
  body .features-main .container {
    padding: 20px;
  }

  body .feature-add {
    width: 50%;
  }

  body .banner-image {
    width: 100%;
    height: 100%;
  }

  body .col-image img {
    height: 100%;
  }

  body .features-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .col-lg-3 {
    margin-top: 20px;
  }

  body section,
  body #pricing {
    padding-top: 30px !important;
  }

  body .inner-banners,
  body .bg-patter {
    padding-bottom: 30px;
  }

  body .features-heading h2 {
    font-size: 20px;
    margin-top: 20px;
  }

  body .col-text,
  body .col-image {
    padding: 10px;
    text-align: left;
  }

  .mb-reverts .mb-revert {
    flex-direction: inherit;
  }

  body .inner_main {
    padding: 20px 0px;
  }
}

.col-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-image img {
  width: 400px;
  height: 400px;
}

.inner_main {
  padding: 50px 0px 50px;
  position: relative;
  background-color: #ffffff;
}

.icon i {
  margin-right: 15px;
  background: #ffd21f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

body .features-mains {
  padding: 0px !important;
  border-radius: 20px 20px 0px 0px;
}

body .about-main {
  padding: 90px 0px;
  border-radius: 20px;
}

.features-main {
  padding: 80px 0px 90px;
  border-radius: 0px 0px 20px 20px;
}

.features-heading p {
  font-size: 15px;
  color: #1b1b1bb0;
  margin-bottom: 0px;
}

.feature-add {
  width: 33.33%;
  margin: 10px 0px;
}

.features-inner {
  display: flex;
}

.features-heading h2 {
  font-size: 20px;
}

.features-inner {
  padding: 35px 30px 35px 20px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #cecece;
  box-shadow: 0px 0px 10px 5px rgb(238 238 238 / 83%);
  border-radius: 10px;
}

.col-image,
.col-text {
  position: relative;
}

.hr-bmp-top {
  margin-top: 40px;
}

.banner-image {
  width: auto;
  height: auto;
}

.col-text {
  padding: 0px 80px 0px 25px;
}

.inner_main img {
  width: 80%;
}

.gray {
  background-color: #fbfcff;
}

.white {
  background-color: #fff;
}

.addon-box-left h3 {
  color: #000 !important;
  font-size: 16px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #000 !important;
  text-decoration: none;
}

.ov-x {
  overflow-x: auto;
}

.ov-x::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 25px;
  opacity: 0;
}

.ov-x::-webkit-scrollbar {
  width: 2px !important;
}

/* Handle on hover */
.ov-x::-webkit-scrollbar-thumb:hover {
  background: #eee;
}

figure.snip {
  position: relative;
  color: #333;
  text-align: left;
  margin: 60px 10px 10px;
  box-shadow: 0px 0px 9px 4px #c2c2c296;
  border-radius: 45px;
  padding: 30px 10px;
}

figure.snip * {
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

/* figure.snip img {
  max-width: 100%;
  vertical-align: middle;
  height: 80px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px 5px #ebebeb;
  border: 5px solid #fff;
  z-index: 9999;
  margin: -77px auto 0px;
  display: block !important;
  width: 80px !important;
  border-radius: 50%;
} */
figure.snip p.text {
  word-wrap: break-word;
  word-break: break-all;
}

figure.snip img {
  width: 120px !important;
  margin-top: -30px;
  margin-bottom: 20px;
}

figure.snip blockquote {
  position: relative;
  padding: 60px 40px 0px 20px;
  font-size: 0.8em;
  line-height: 1.6em;
  font-style: normal;
}

figure.snip blockquote:before {
  font-family: "FontAwesome";
  content: "\201C";
  position: absolute;
  font-size: 50px;
  opacity: 1;
  color: #000;
  font-style: normal;
}

tbody .hover:nth-child(odd) {
  background: #fafafa;
}

.acc h5 {
  font-weight: 600;
  margin-bottom: 25px;
  color: darkblue;
}

.acc {
  padding: 0px 40px 25px 20px;
}

figure.snip blockquote:before {
  top: 25px;
  left: 20px;
  font-size: 80px !important;
}

figure.snip .author span a {
  color: #000 !important;
  opacity: 1;
  font-size: 10px;
  font-weight: 600;
}

figure.snip blockquote:after {
  content: "\201D";
  opacity: 0;
  right: 20px;
  bottom: 0;
}

figure.snip .author {
  text-transform: uppercase;
  color: #ffffff;
  padding: 0px 40px 0px 20px;
  text-align: center;
}

figure.snip .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
  font-size: 14px;
  text-align: left;
}

span.read-or-hide {
  color: #40bffa;
}

figure.snip .author h5 span {
  font-weight: 400;
  text-transform: none;
  font-size: 10px;
  text-transform: uppercase;
}

.addon-box-left:after {
  content: "";
  border-color: var(--gray-color) var(--gray-color) transparent transparent;
  width: 110px;
  height: 111px;
  display: block;
  position: absolute;
  bottom: -149px;
  left: -126px;
  border-radius: 100%;
  border-width: 110px;
  border-style: solid;
}

.addon-box-left:after {
  border-color: var(--yellow-color) var(--yellow-color) transparent transparent;
}

.addon-box-left {
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 77px #0000000f;
  transition: all 0.3s linear;
  padding: 20px 5px;
  height: 9em;
  margin: 10px auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

span.addons-line.text-center {
  margin-top: 10px !important;
  height: auto !important;
  display: block;
}

.addons-price {
  color: #1800FB;
  font-size: 25px;
}

.addon-box h3 {
  font-size: 16px !important;
  font-family: 'Lato', sans-serif;
  font-weight: 400 !important;
  text-align: left;
  margin-bottom: 10px !important;
}

span.addons-line.text-center p {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  line-height: 15px !important;
  margin-bottom: 0px;
  min-height: 45px;
}

a.text-decoration-underline.free-packages.free-addons {
  text-align: center !important;
  width: 100% !important;
  display: inline-block;
  margin: 20px 0px 0px;
}

.addons-btn {
  border: 1.16129px solid var(--orange-color);
  box-sizing: border-box;
  box-shadow: 0 9.10816px 22.7704px rgba(104, 162, 238, 0.2);
  border-radius: 80.45px;
  background: #f8fbfb;
  text-decoration: none;
  padding: 9px;
  color: var(--orange-color) !important;
  font: 600 15px/1 Axiforma, Arial, serif;
  position: relative;
}

.cs-btn {
  border: 1.16129px solid var(--orange-color);
  box-sizing: border-box;
  box-shadow: 0 9.10816px 22.7704px rgba(104, 162, 238, 0.2);
  border-radius: 80.45px;
  background: #f8fbfb;
  text-decoration: none;
  padding: 15px;
  color: var(--orange-color);
  font: 600 15px/1 Axiforma, Arial, serif;
  position: relative;
}

.anim {
  -webkit-animation: fadein 1s;
  animation: fadein 1s;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pricechecbox {
  background-color: #ffffff;
  transition: 0.5s all ease;
  color: #000;
  border: 1px solid #1B1363;
  transition: all 0.2s ease-in-out 0s;
}

.lbl_mo,
.lbl_yr {
  padding: 15px 35px;
  border-radius: 100px;
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

a {
  cursor: pointer;
}

.lbl_mo span,
.lbl_yr span {
  font-weight: 300;
  font-size: 10px;
}

#group1 {
  margin-bottom: 30px;
}

/* switch end */

table {
  border-spacing: 0 !important;
}

.heading {
  background-color: #ebe8e8;
}

.custom_div {
  background: #fff;
  padding: 50px;
  border-radius: 10px;
  width: 500px;
  margin: 0 auto;
}

.sub_border {
  border-radius: 25px 0px 0px 25px;
}

.custom_scroll {
  height: 495px;
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #eee;
}

.sb-main.sb-chat.sb-no-conversations {
  display: block !important;
}

#responsive-dialog-title {
  font-size: 30px;
  line-height: 44px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-bottom: 40px;
  width: 100%;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  padding: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 99px #0607071a !important;
  border-radius: 10px !important;
}

.tasktak_modal-close {
  color: #b5b5b5 !important;
  width: 41px !important;
  height: 41px !important;
}

.tasktak_modal-close:hover {
  transform: rotate(90deg);
  transition: all 0.2s ease 0s;
}

.tasktak_form-control {
  background: #fefefe 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  opacity: 1;
  padding: 10px 15px;
  width: 100%;
  outline: none !important;
}

.tasktak_form-label {
  text-align: left;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
  display: block;
}

::placeholder {
  text-align: left;
  font-size: 14px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: 0.32px;
  color: #babfc7;
  opacity: 1;
}

read-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.read-btn {
  font-size: 15px !important;
}

h5.most-popular {
  background: #ff060685;
  text-align: center;
  padding: 10px 20px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  color: #1B1363;
  font-size: 10px;
  border-radius: 10px;
}

.best-seller {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sticky {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 99;
}

.content-container {
  padding: 1rem 4rem;
}

.hierarchy {
  margin-left: 10px;
  padding: 10px;
}

.icon-bx-wraper.style-1 {
  padding: 30px 20px;
  transition: all 0.5s;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 77px #0000000f;

  margin-bottom: 20px;
}

.privacy-conatiner ul li {
  list-style: inside !important;
}

@media (max-width: 600px) {
  .icon-bx-wraper.style-1 {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .navbar-light .navbar-nav .nav-link,
  .navbar-light .nav-item {
    display: block !important;
  }

  .pricing-bgw.bannerss.inner-bannersw.position-relative.text-center.text-md-start {
    margin-top: 120px;
  }

  .custom-col-width {
    width: 100% !important;
  }

  .addon-box {
    margin: 10px 0px !important;
  }

  .my-mbs {
    margin-bottom: 120px;
  }

  .cs-add {
    flex-basis: auto !important;
  }

  .hierarchy {
    font-size: 25px !important;
    font-weight: 600 !important;
  }
}

.cs-add {
  flex: 1;
}

a.icon-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.icon-bx-md.radius.bg-yellow.shadow-yellow {
  display: flex;
  justify-content: space-around;
}

.addon-new ul li {
  list-style: none;
  color: #484848;
  font-size: 12px;

  border-radius: 10px;
  font-family: verdana;

  letter-spacing: 2px;
  animation: animate 1.4s linear infinite;
}

.addon-new ul {
  position: absolute;
  padding: 0px 4px;
  margin: 0;
  padding: 0;
  background: aliceblue;
  display: flex;
}

span.addon-new {
  position: absolute;
}

.addon-new.addon-new ul li:nth-child(1) {
  animation-delay: 0.2s;
}

.addon-new ul li:nth-child(2) {
  animation-delay: 0.4s;
}

.addon-new ul li:nth-child(3) {
  animation-delay: 0.6s;
}

.addon-new ul li:nth-child(4) {
  animation-delay: 0.8s;
}

.addon-new ul li:nth-child(5) {
  animation-delay: 1s;
}

.addon-new ul li:nth-child(6) {
  animation-delay: 1.2s;
}

.addon-new ul li:nth-child(7) {
  animation-delay: 1.4s;
}

@keyframes animate {
  0% {
    color: #484848;
    text-shadow: none;
    transform: scale(1);
  }

  90% {
    color: #484848;
    text-shadow: none;
    transform: scale(1);
  }

  100% {
    color: #40bffa;
    text-shadow: 0 0 7px #fff900, 0 0 50px #ff6c00;
    transform: scale(1.5);
  }
}

.icon-cell p {
  text-align: right;
  color: #40bffa;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
}

.icon-content.add-description p {
  font-size: 13px;
}

.icon-bx-wraper {
  position: relative;
}

.dlab-title {
  height: 2em;
}

.add-description {
  height: 5em;
}

.icon-content {
  overflow: hidden;
}

.icon-bx-wraper.style-1>[class*="icon-bx-"] {
  position: relative;
  z-index: 1;
}

.icon-bx-md.radius {
  border-radius: 100%;
}

.icon-bx-wraper.style-1.active>[class*="icon-bx-"]:after,
.icon-bx-wraper.style-1:hover>[class*="icon-bx-"]:after {
  right: 30px;
}

.icon-bx-wraper.style-1>[class*="icon-bx-"].bg-yellow:after {
  background-image: url();
}

.hierarchy {
  font-size: 35px;
  font-weight: 700;
  position: relative;
}

.icon-bx-wraper.style-1 .dlab-title {
  margin-bottom: 15px;
  color: #1f2471;
  font-size: 20px;
  font-weight: bold;
}

.icon-bx-wraper.style-1>[class*="icon-bx-"]:after {
  content: "";
  position: absolute;
  right: -20px;
  top: -20px;
  height: 133px;
  width: 82px;
  background-position: 100%;
  z-index: -1;
  transition: all 0.5s;
}

.icon-bx-md {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
}

.icon-bx-md i {
  font-size: 19px;
  vertical-align: middle;
  font-weight: bold;
  font-style: normal;
  color: #000;
}

.bg-yellow {
  background-color: #fff;
}

.icon-bx-wraper.style-1.active:after,
.icon-bx-wraper.style-1.active:before,
.icon-bx-wraper.style-1:hover:after,
.icon-bx-wraper.style-1:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.icon-bx-wraper.style-1:before {
  background-image: url("../public/assets/images/bg-icon-box.png");
  height: 100%;
  top: -30px;
  background-size: cover;
  left: -30px;
  width: 100%;
}

.icon-bx-wraper.style-1:after,
.icon-bx-wraper.style-1:before {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  z-index: 1;
}

.icon-bx-wraper.style-1.active:after,
.icon-bx-wraper.style-1.active:before,
.icon-bx-wraper.style-1:hover:after,
.icon-bx-wraper.style-1:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #000;
  text-decoration: none;
}


.icon-bx-wraper.style-1:after {
  background-image: url("../public/assets/images/icon-bg-2.png");
  height: 100px;
  bottom: 10px;
  right: -35px;
  width: 261px;
}

.icon-bx-wraper.style-1:after,
.icon-bx-wraper.style-1:before {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  z-index: 1;
}

.icon-bx-wraper.style-1.active,
.icon-bx-wraper.style-1:hover {
  box-shadow: 0 0 10px 4px rgb(31 66 135 / 10%);
}

.feature-down {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  background: #ffffff;
  border-radius: 25px;
  padding: 5px 5px 3px 5px;
}

button.cs-btn.rotate img {
  rotate: 180deg;
  transition: all 0.3s ease-in;
}

.add-desc {
  font-size: 15px;
}

.add-price {
  font-size: 17px;
}

.text-left {
  text-align: left !important;
}

.spacer {
  width: 1.5rem;
}



table {
  caption-side: bottom;
  border-collapse: collapse;
  width: 100%;
}

.bt-radius {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.bb-radius {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

td {
  padding: 0 10px;
}

.hover {
  z-index: 100;
}

.ml-1 {
  padding-left: 2px;
}

fieldset#group {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 15px -6px rgb(119 119 119 / 40%);
  background: #DEE2EA;
  border-radius: 100px;
}

input[type="radio"] {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.hover:hover td {
  background: rgba(199, 197, 197, 0.5);
}

td {
  font-size: 11px;
}

.pb {
  padding: 10px 5px;
  width: 200px;
}

tr td img {
  width: 28.5px;
  border-radius: 25px;
  padding: 5px;
  height: 28.5px;
}

.bg-color .check-img path {
  fill: blue;
}

.banner {
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("../public/assets/images/banner_three_banner_shape_02.png");
  height: 100%;
  width: 50%;
  background-repeat: no-repeat;
  display: block;
  z-index: -1;
}

.banner::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background-image: url("../public/assets/images/banner_three_banner_shape_01.png");
  height: 100%;
  width: 899px;
  background-repeat: no-repeat;
  display: block;
  z-index: -1;
} */

/* Services Css */

.service__section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 198px;
  background-image: url("../public/assets/images/Polygon-2-3.png");
  height: 408px;
  width: 186px;
  background-repeat: no-repeat;
  display: block;
  z-index: -1;
  bottom: 0;
  margin: auto;
}

.service__section::after {
  content: "";
  position: absolute;
  right: -382px;
  top: 0;
  background-repeat: no-repeat;
  display: block;
  z-index: -1;
  width: 468px;
  height: 465px;
  background: #ffc962 0% 0% no-repeat padding-box;
  border-radius: 100%;
  bottom: 0;
  margin: auto;
}

.service-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 77px #0000000f;
  border-radius: 15px;
  border: 3px solid #fff;
  transition: all 0.3s linear;
}

.service-box span {
  width: 55px;
  height: 55px;
  text-align: center;
  margin-bottom: 20px !important;
}

.service-box span img {
  width: 60px;
  height: 60px;
}

.tt_pricing {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 50px;
}

.pricing-bgw div#price_4 {
  order: 1;
}

div#price_4 .price-box:hover {
  background-image: linear-gradient(#1800FD, #1002B7) !important;
  color: #fff !important;
}

div#price_4 .price-box:hover span,
div#price_4 .price-box:hover a,
div#price_4 .price-box:hover h3,
div#price_4 .price-box:hover li,
div#price_4 .price-box:hover p {
  color: #fff !important;

}

div#price_5 .price-box:hover,
div#price_11 .price-box:hover,
div#price_12 .price-box:hover,
div#price_13 .price-box:hover,
div#price_14 .price-box:hover,
div#price_15 .price-box:hover {
  background-image: linear-gradient(#1800FD, #1002B7) !important;
  color: #fff !important;
}

div#price_5 .price-box:hover span,
div#price_5 .price-box:hover a,
div#price_5 .price-box:hover h3,
div#price_5 .price-box:hover li,
div#price_5 .price-box:hover p,
div#price_11 .price-box:hover span,
div#price_11 .price-box:hover a,
div#price_11 .price-box:hover h3,
div#price_11 .price-box:hover li,
div#price_11 .price-box:hover p,
div#price_12 .price-box:hover span,
div#price_12 .price-box:hover a,
div#price_12 .price-box:hover h3,
div#price_12 .price-box:hover li,
div#price_12 .price-box:hover p,
div#price_13 .price-box:hover span,
div#price_13 .price-box:hover a,
div#price_13 .price-box:hover h3,
div#price_13 .price-box:hover li,
div#price_13 .price-box:hover p,
div#price_14 .price-box:hover span,
div#price_14 .price-box:hover a,
div#price_14 .price-box:hover h3,
div#price_14 .price-box:hover li,
div#price_14 .price-box:hover p,
div#price_15 .price-box:hover span,
div#price_15 .price-box:hover a,
div#price_15 .price-box:hover h3,
div#price_15 .price-box:hover li,
div#price_15 .price-box:hover p {
  color: #fff !important;
}

.d-bg-block.main {
  display: block !important;
}

@media (min-width: 1025px) {
  h5.fs-5.lh-23.mb-0.text-orange.custom-sub-title {
    font-size: 15px !important;
    white-space: nowrap;
  }
}

.pb {
  padding: 20px 5px;
}

.bg-color {
  text-align: center;
}

.review-img.col-6 img {
  width: 400px !important;
  margin: 0 auto;
  position: relative;
  z-index: 999999;
  object-fit: contain;
  height: 400px;
}

p.custom-starting {
  padding: 28px 0px;
  margin-bottom: 0px;
  font-size: 15px;
}

.review-img.col-6 {
  position: relative;
}

.custom-reviewss {
  margin-bottom: 45px;
  ;
}

.review-icon h3 {
  font-size: 50px;
}

.my-owol .owl-dots {
  position: relative;
  top: -80px !important;
  margin-left: 185px;
  z-index: -1;
}

.my-owol .owl-dots .owl-dot.active span {
  width: 15px;
}

.my-owol .owl-dots .owl-dot span {
  width: 15px !important;
  height: 15px !important;
  background: #00298661 !important;
}

.review-img.col-6 .img-before:after {
  content: "";
  position: absolute;
  background: #291763;
  height: 90px;
  width: 90px;
  right: 110px;
  bottom: -20px;
  z-index: -1;
}

.review-team p {
  font-size: 23px;
  font-weight: 500;
}

.review-star span {
  margin-left: 30px;
  color: #1002B7;
}

.review-team h6 {
  font-size: 25px;
  font-weight: 600;
}

.review-description {
  padding-bottom: 30px;
}

.review-star {
  display: flex;
  align-items: center;
  padding: 10px 0px 30px;
}

.review-star img {
  width: 140px !important;
}

.d-none.anim.ov-x.section-footer-top.main {
  display: block !important;
}

div#price_5 .price-box:hover a.text-decoration-none.text-white,
div#price_11 .price-box:hover h5.most-popular,
div#price_14 .price-box:hover h5.most-popular,
div#price_11 .price-box:hover a.text-decoration-none.text-white,
div#price_12 .price-box:hover a.text-decoration-none.text-white,
div#price_13 .price-box:hover a.text-decoration-none.text-white,
div#price_14 .price-box:hover a.text-decoration-none.text-white,
div#price_15 .price-box:hover a.text-decoration-none.text-white {
  background: #fff !important;
  color: #1800FD !important;
}

div#price_5 .price-box:hover .free-packages img,
div#price_11 .price-box:hover .free-packages img,
div#price_12 .price-box:hover .free-packages img,
div#price_13 .price-box:hover .free-packages img,
div#price_14 .price-box:hover .free-packages img,
div#price_15 .price-box:hover .free-packages img {
  filter: invert(1);
}

.check-tt ul {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.check-tt ul li img {
  padding-right: 20px;
}

.check-tt ul li {
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lbl__yr {
  color: #378D00 !important;
  font-weight: bold !important;
  font-size: 11px !important;
}

div#price_4 .price-box:hover a.text-decoration-none.text-white {
  color: #1B1363 !important;
  background-image: linear-gradient(#ffffff, #ffffff) !important;
}

.accordion-flush .accordion-item .accordion-button {
  font-weight: 500;
  color: #1B1363 !important;
  font-size: 18px;
}

.tt_fb {
  font-weight: 600;
  color: #40bffa;
}

.free-packages.custom-opacity {
  opacity: 0;
  visibility: hidden;
}

.tt_text {
  position: absolute;
  top: 59px;
  margin-left: 45px;
  width: 100px;
  font-size: 12px;
}

fieldset.group2 {
  background-color: rgb(223, 227, 235);
  border: 1px solid rgb(203, 214, 226);
  border-radius: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.tt_img {
  animation-name: float_left_right_two;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float_left_right_two;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  position: relative;
  top: -50px;
}

@keyframes float_left_right_two {
  0% {
    transform: translateX(30px);
    opacity: 1;
  }

  50% {
    transform: translateX(-0px);
    opacity: 0.8;
  }

  100% {
    transform: translateX(30px);
    opacity: 1;
  }
}

.tt_img img {
  width: 40px;
  position: absolute;
  top: 46px;
  transform: rotate(156deg);
}

div#serv_1:hover .service-box,
div#serv_9:hover .service-box {
  border: 3px solid var(--link-color);
}

div#serv_2:hover .service-box,
div#serv_19:hover .service-box {
  border: 3px solid var(--link-color);
}

div#serv_3:hover .service-box,
div#serv_11:hover .service-box {
  border: 3px solid var(--link-color);
}

div#serv_4:hover .service-box,
div#serv_12:hover .service-box {
  border: 3px solid var(--link-color);
}

div#serv_5:hover .service-box {
  border: 3px solid var(--link-color);
}

div#serv_6:hover .service-box {
  border: 3px solid var(--link-color);
}

div#serv_7:hover .service-box {
  border: 3px solid var(--link-color);
}

div#serv_7:hover .service-box {
  border: 3px solid var(--link-color);
}

div#serv_8:hover .service-box {
  border: 3px solid var(--link-color);
}

.ellipse-img {
  position: absolute;
  left: -188px;
}

.ellipse-img-2 {
  position: absolute;
  right: -188px;
  top: 0;
}

.feature-box {
  background: #ffd21f 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 77px #0000000f;
  border-radius: 15px;
}

.feature-box h2,
.feature-box p {
  color: #1b1b1b !important;
}

/* our__screens */

/* .screen img {
    width: 750px !important;
    margin: 0 auto;
} */
.cs-text {
  font-size: 14px;
  font-weight: 700;
  color: var(--yellow-color);
}

span.cs-buy {
  font-size: 10px;
  background: antiquewhite;
  padding: 2px 7px;
  border-radius: 6px;
  position: absolute;
  height: auto !important;
  top: 3px;
  width: auto;
  margin-left: 10px;
}

a.cs-link {
  width: auto !important;
  display: initial !important;
  background: transparent !important;
  text-decoration: none;
  font-size: inherit;
  margin: 0px !important;
  text-align: left !important;
  padding: 0px !important;
  color: inherit !important;
}

.mb-2 {
  position: relative;
}

#about .col-12.col-md-8.mx-auto.text-center p {
  text-align: left !important;
}

section,
#pricing {
  padding-top: 90px !important;
}

.Prompt__MessageBubble {
  border: 1px solid rgb(232, 233, 235);
  position: fixed;
  right: 20px;
  bottom: 100px;
  box-shadow: rgb(0 0 0 / 24%) 0px 8px 20px 0px;
  background: rgb(255, 255, 255);
  border-radius: 13px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  max-width: 290px;
  padding: 13px;
  width: 100%;
  z-index: 99999;
}

.lc_text-widget .circular-image {
  background-position: 50% center;
  border-radius: 45px;
  width: 45px;
  height: 45px;
}

.Prompt__PromptText::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -6px;
  right: 18px;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  z-index: -1;
  transform: rotate(45deg);
}

.Prompt__AvatarCustom {
  background-color: rgb(228, 233, 240);
  background-repeat: no-repeat;
  border-radius: 100px;
  background-size: cover;
}

.Prompt__PromptText {
  padding-left: 12px;
  font-size: 16px;
  line-height: 1.4;
  max-width: 190px;
}

.circular-image {
  background-position: 50% center;
  border-radius: 45px;
  width: 45px;
  height: 45px;
}

.bg-tk {
  background-color: #fff;
  padding: 30px 0px;
}

.our__screen__conatiner:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -600px;
  background-image: url("../public/assets/images/gray-bg.png");
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  display: block;
  z-index: -1;
  bottom: 0;
  margin: auto;
}

@media (max-width: 600px) {
  .price-box {
    height: 100% !important;
  }

  div#price_2 {
    margin-top: 20px;
  }
}

li.mb-2.ml-2 {
  margin-left: 20px;
}

/* Pricing Plane */

.price-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #00000029;
  transition: all 0.3s linear;
  padding: 30px 20px 30px;
  border-radius: 39px !important;
}

.custom-col-width {
  width: 20%;
}

span.month-addons {
  margin-bottom: -7px !important;
  font-size: 13px;
  margin-left: 30px;
  color: #000 !important;
}

.addons-title {
  font-size: 40px;
  font-family: 'Montserrat';
  color: #1b1b1b;
}

.addons-bg {
  background: #F5F5F5;
  padding: 50px 0px !important;
  margin-top: 20px !important;
}

div#add5 .addon-box:hover {
  background-image: linear-gradient(#1800FD, #1002B7) !important;

}

div#add4 .addon-box:hover,
div#add3 .addon-box:hover,
div#add2 .addon-box:hover,
div#add1 .addon-box:hover {
  background-image: linear-gradient(#1800FD, #1002B7) !important;
}

div#add5 .addon-box:hover h3,
div#add5 .addon-box:hover p,
div#add5 .addon-box:hover a,
div#add5 .addon-box:hover span {
  color: #fff !important;
}

div#add4 .addon-box:hover img,
div#add3 .addon-box:hover img,
div#add2 .addon-box:hover img,
div#add1 .addon-box:hover img {
  filter: invert(1);
}

div#add1 .addon-box:hover h3,
div#add1 .addon-box:hover p,
div#add1 .addon-box:hover a,
div#add1 .addon-box:hover span,
div#add2 .addon-box:hover h3,
div#add2 .addon-box:hover p,
div#add2 .addon-box:hover a,
div#add2 .addon-box:hover span,
div#add3 .addon-box:hover h3,
div#add3 .addon-box:hover p,
div#add3 .addon-box:hover a,
div#add3 .addon-box:hover span,
div#add4 .addon-box:hover h3,
div#add4 .addon-box:hover p,
div#add4 .addon-box:hover a,
div#add4 .addon-box:hover span {
  color: #fff !important;
}

ul.list-unstyled.text-gray.small.mb-3 li {
  font-family: 'Lato', sans-serif;
  font-size: 15px !important;
}

div#add5 .addon-box img {
  filter: invert(1);
}

.addon-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #00000029;
  transition: all 0.3s linear;
  padding: 25px 20px 20px;
  border-radius: 39px !important;
}

.read-div {
  display: block;
  width: 200px;
  margin: 10px auto 30px;
}

.price-box span {
  text-align: center;
  word-break: break-word;
  line-height: 1;
  margin-bottom: 30px !important;
  font-size: 34px;
  font-weight: 400 !important;
  color: #1800FD !important;
}

span.month {
  margin-bottom: -15px !important;
  font-size: 13px;
  margin-left: 30px;
  color: #000 !important;
}

html body .free-packages {
  font-size: 12px;
  margin: 20px 0px;
  color: #000 !important;
  letter-spacing: 1px;
  font-family: 'Lato', sans-serif;
  font-weight: bold !important;
  background: transparent !important;
  text-decoration: underline !important;
  width: 100%;
}

.heading strong::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  margin-top: 2px;
  background: white;
  border-radius: 999px;
  left: 6px;
  border: 3px solid #40bffa;
}

.cs-btn {
  background-image: linear-gradient(#1800FD, #1002B7) !important;
  display: block;
  font-size: 16px;
  transition: all 1s ease-in;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 12px 40px;
  text-align: center;
}

.price-box a.text-decoration-none.text-white {
  padding: 10px 0px;
}

.heading {
  padding-left: 25px;
}

table {
  margin-left: 10px;
}

ul.list-unstyled.text-gray.small.mb-3.custom-ul {
  max-width: 135px;
  margin: 0 auto;
}

ul.list-unstyled.text-gray.small.mb-3 li {
  font-family: 'Lato', sans-serif;
}

tbody {
  position: relative;
  margin-left: 10px;
}

p.tag-line {
  font-size: 14px;
  line-height: 20px !important;
  font-weight: 400;
  margin-bottom: 32px;
  text-align: left;
}
div#price_25,div#price_26 {
  margin: 0 auto;
}
ul.list-unstyled.text-gray.small.mb-3.custom-uls li {
  font-size: 12px!important;
}

ul.list-unstyled.text-gray.small.mb-3.custom-uls {
  width: 50%;
  float: left;
}
.price-box h3.text-second.fs-4.mb-3.text-left.position-relative {
  font-size: 30px !important;
  font-family: 'Lato', sans-serif;
}

img.ml-3 {
  margin-left: 5px !important;
}

.price-box a {
  width: 100%;
  background-image: linear-gradient(#1800FD, #1002B7) !important;
  display: block;
  font-size: 16px;
  border-radius: 10px;
  margin: 0 auto;
  padding: 12px;
  text-align: center;
}

/* .price-box:before {
  content: "";
  width: 111px;
  height: 110px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url("../public/assets/images/corner.png");
} */

/* .price-box h3:before {
  content: "";
  width: 40px;
  height: 2px;
  background: #24344a;
  top: -12px;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  position: absolute;
} */

/* .price-box:after {
  content: "";
  border-color: var(--gray-color) var(--gray-color) transparent transparent;
  width: 110px;
  height: 111px;
  display: block;
  position: absolute;
  bottom: -149px;
  left: -126px;
  border-radius: 100%;
  border-width: 110px;
  border-style: solid;
} */

#price_2 .price-box:after {
  border-color: var(--red-color) var(--red-color) transparent transparent;
}

#price_3 .price-box:after {
  border-color: var(--yellow-color) var(--yellow-color) transparent transparent;
}

#price_5 .price-box:after {
  border-color: var(--red-color) var(--red-color) transparent transparent;
}

#price_4 .price-box:after {
  border-color: var(--link-color) var(--link-color) transparent transparent;
}

#price_5 span {
  color: var(--gray-color);
}

#price_5 a {
  background: var(--red-color);
  color: var(--gray-color);
}

#price_1 span {
  color: var(--gray-color);
}

#price_1 a {
  background: var(--gray-color);
}

#price_1 i {
  color: var(--gray-color);
}

#price_2 span {
  color: var(--red-color);
}

#price_2 a {
  background: var(--red-color);
}

#price_2 i {
  color: var(--red-color);
}

#price_3 span {
  color: var(--yellow-color);
}

#price_3 a {
  background: var(--yellow-color);
}

#price_3 i {
  color: var(--yellow-color);
}

#price_4 span {
  color: var(--link-color);
}

#price_4 a {
  background: var(--link-color);
}

#price_4 i {
  color: var(--link-color);
}

.img-box {
  box-shadow: 0px 3px 20px #00000029;
}

.img-box {
  width: 200px !important;
  margin: 0 auto;
}

.accordion-flush .accordion-item .accordion-button {
  font-weight: 500;
}

.accordion-button:not(.collapsed) {
  color: var(--main-color);
  background-color: #fff;
  box-shadow: unset;
  font-weight: 500;
}

.accordion-body {
  padding: 0 1.25rem 1rem;
}

.accordion-flush .accordion-item {
  margin-bottom: 15px;
  border: 0;
  box-shadow: 0px 3px 40px #e5e9f6;
  border-radius: 14px;
}

.social__box {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  background: rgb(23 32 63 / 75%);
  border-radius: 100%;
  bottom: 0;
  right: 0;
  margin: auto;
}

.social__box img {
  width: auto !important;
}

.img-box:hover .social__box {
  display: block;
}

.subscribe__box {
  background-image: url("../public/assets/images/footer-bg.png");
  height: 781px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: -230px;
}

@media (max-width: 1023px) {
  nav.navbar {
    box-shadow: 0px 3px 40px #00000029;
    background: #fff;
  }
}

@media (max-width: 1024px) {
  .ellipse-img-2 {
    position: absolute;
    right: -21px;
    top: 68px;
    width: 75px;
  }
  .our__screen__conatiners.position-relative.bg-white .item img{
    width: 100%!important;
  }

  .ellipse-img {
    position: absolute;
    left: -50px;
    width: 75px;
    top: 108px;
  }
}
.our__screen__conatiners .owl-nav {
  position: absolute;
  margin: 0 auto !important;
  left: -10px;
  right: 0;
  width: 90%;
  bottom: 45%;
}
figure.snip img.my-r-v-i {
  border-radius: 100px;
  width: 50px!important;
  margin: 0px 40px 30px 20px;
}
@media (max-width: 767px) {
  html {
    overflow-x: hidden;
  }
  .owl-carousel .owl-nav button{
    width: 28px;
    height: 28px;
  }
  .ft-p-0 {
    margin-bottom: 0px!important;
    margin-top: 0px!important;
}
figure.snip{
  margin: 10px!important;
}
  .cta-inner-ngo .justify-content-end {
    justify-content: center!important;
    margin-top: 20px;
}
.cta-inner-ngo h3 {
  text-align: center;
}
  .our__screen__conatiners .owl-nav {
    position: absolute;
    margin: 0 auto !important;
    left: 0px!important;
    right: 0;
    width: 100%!important;
    bottom: 45%;
}
  .col-text p ,p ,.ngo-check ul li{
    font-size: 14px;
  }

  html {
    font-size: 80%;
  }

  .banner {
    height: auto;
    padding-top: 88px;
  }

  img.logo {
    width: 110px;
  }

  .lh-35 {
    line-height: 1.2;
  }

  .service__section::before {
    left: -80px;
    top: 616px;
    height: auto;
  }

  .service__section::after {
    right: -382px;
    top: -1190px;
    width: 416px;
    height: 368px;
  }

  .ellipse-img {
    position: absolute;
    left: -58px;
    width: 77px;
    top: 45px;
  }

  .ellipse-img-2 {
    position: absolute;
    right: -59px;
    top: unset;
    width: 77px;
    margin-top: 45px;
  }

  .owlimg {
    width: 90px;
    top: 30px !important;
    z-index: 9;
  }

  .subscribe__box {
    height: 422px;
    margin-bottom: -124px;
  }

  .owl-nav {
    width: 95%;
  }
}