.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {

    *,
    *::before,
    *::after {
        text-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: " (" attr(title) ")";
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body {
        min-width: 992px !important;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }

    .badge {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: #fff !important;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #dee2e6 !important;
    }

    .table-dark {
        color: inherit;
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody+tbody {
        border-color: #dee2e6;
    }

    .table .thead-dark th {
        color: inherit;
        border-color: #dee2e6;
    }
}

a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    color: #40bffa;
}

a:hover,
a:focus {
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.bg-primary {
    background: #40bffa !important;
}

.ftco-section {
    padding: 7em 0;
}

.ftco-no-pt {
    padding-top: 0;
}

.ftco-no-pb {
    padding-bottom: 0;
}

.heading-section {
    font-size: 28px;
    color: #000;
}

.img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.form-control {
    height: 36px;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    border-radius: 2px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(0, 0, 0, 0.3) !important;
}

.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.3) !important;
}

.form-control:-ms-input-placeholder {
    /* IE 0+ */
    color: rgba(0, 0, 0, 0.3) !important;
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.3) !important;
}

.form-control:focus,
.form-control:active {
    border-color: #40bffa !important;
}

textarea.form-control {
    height: inherit !important;
}

.wrapper {
    width: 100%;
    -webkit-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
}

.contact-wrap {
    background: #fff;
}

.info-wrap {
    color: rgba(255, 255, 255, 0.8);
}

.info-wrap h3 {
    color: #fff;
}

.info-wrap .dbox {
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 25px;
}

.info-wrap .dbox:last-child {
    margin-bottom: 0;
}

.info-wrap .dbox p {
    margin-bottom: 0;
}

.info-wrap .dbox p span {
    font-weight: 500;
    color: #fff;
}

.info-wrap .dbox p a {
    color: #fff;
}

.info-wrap .dbox .icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.info-wrap .dbox .icon span {
    font-size: 20px;
    color: #fff;
}

.info-wrap .dbox .text {
    width: calc(100% - 50px);
}

.btns {
    padding: 12px 16px;
    cursor: pointer;
    border-width: 1px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.btns:hover,
.btns:active,
.btns:focus {
    outline: none !important;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.22) !important;
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.22) !important;
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.22) !important;
}

.btns.btn-primary {
    background: #40bffa !important;
    border-color: #40bffa !important;
    color: #fff;
}

.btns.btn-primary:hover,
.btns.btn-primary:focus {
    border-color: #40bffa !important;
    background: #40bffa !important;
}

.contactForm .label {
    color: #000;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.contactForm .form-control {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
}

#contactForm .error {
    color: red;
    font-size: 12px;
}

#contactForm .form-control {
    font-size: 16px;
}

#message {
    resize: vertical;
}

#form-message-warning,
#form-message-success {
    display: none;
}

#form-message-warning {
    color: red;
}

#form-message-success {
    color: #28a745;
    font-size: 18px;
    font-weight: bold;
}

.submitting {
    float: left;
    width: 100%;
    padding: 10px 0;
    display: none;
    font-size: 16px;
    font-weight: bold;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.form-group {
    margin-bottom: 1rem;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

.contact-height {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-banner::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../public/assets/images/banner_three_banner_shape_02.png');
    height: 100%;
    width: 50%;
    background-repeat: no-repeat;
    display: block;
    z-index: -1;
}

.contact-banner::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    background-image: url('../public/assets/images/banner_three_banner_shape_01.png');
    height: 100%;
    width: 899px;
    background-repeat: no-repeat;
    display: block;
    z-index: -1;
}

@media (max-width: 1024px) {
    .contact-height {
        height: auto !important;
        display: block !important;
    }

    .order-md-last {
        order: 6 !important;
    }
}