:root {
    --orange-color: #40bffa;
    --orange-colors: #7350ba;
    --main-color: #17203F;
    --second-color: #37474F;
    --gray-color: #707070;
    --yellow-color: #7350ba;
    --red-color: #17203f;
    --link-color: #717CFF;
    --heading: #17e0b6;
    --color-primary: #2f57ef;
    --color-secondary: #b966e7;
    --color-coral: #E9967A;
    --color-violet: #800080;
    --color-pink: #DB7093;
    --color-heading: #192335;
    --color-body: #6b7385;
    --color-white: #ffffff;
    --color-white-off: #FFFFFFAB;
    --color-bodyest: #273041;
    --color-dark: #27272E;
    --color-darker: #192335;
    --color-black: #111113;
    --color-grey: rgba(207,207,207,.24);
    --color-grey2: #f4f5f7;
    --color-gray: #A1A9AC;
    --color-gray-light: #F6F6F6;
    --color-gray-lighter: #ebebeb;
    --color-light: #F5F7FA;
    --color-lighter: #F2F5F9;
    --primary-opacity: #2f57ef21;
    --secondary-opacity: #b966e721;
    --coral-opacity: #E9967A21;
    --violet-opacity: #80008021;
    --pink-opacity: #DB709321;
    --white-opacity: #ffffff21;
    --danger-opacity: #FF000310;
    --warning-opacity: #FF8F3C10;
    --success-opacity: #3EB75E10;
    --black-opacity: rgba(0, 0, 0, 0.04);
    --heading-opacity: #19233550;
    --color-card-1: #fffccf;
    --color-card-2: #ffedff;
    --color-card-3: #ffe8eb;
    --color-card-4: #e9f6ff;
    --color-card-5: #5024D1;
    --color-card-6: #3B3B46;
    --color-success: #3EB75E;
    --color-danger: #FF0003;
    --color-warning: #FF8F3C;
    --color-info: #1BA2DB;
    --color-primary-alt: #D1EBE6;
    --color-primary-light: #f1f7ff;
    --color-primary-lighter: #EEEEFF;
    --color-secondary-alt: #fef4f4;
    --color-extra: rgb(249, 249, 255);
    --color-extra2: #F9F9FF;
    --color-border: #e6e3f1;
    --color-border-2: #e6e3f14f;
    --color-badge-1: #303F5B;
    --color-badge-2: #F2C94C;
    --color-facebook: #3B5997;
    --color-twitter: #1BA1F2;
    --color-youtube: #ED4141;
    --color-linkedin: #0077B5;
    --color-pinterest: #E60022;
    --color-instagram: #C231A1;
    --color-vimeo: #00ADEF;
    --color-twitch: #6441A3;
    --color-discord: #7289da;
    --radius-10: 10px;
    --radius-big: 16px;
    --radius-small: 6px;
    --border-width: 2px;
    --radius: 6px;
    --f-light: 300;
    --f-regular: 400;
    --f-medium: 500;
    --f-semi-bold: 600;
    --f-bold: 700;
    --f-extra-bold: 800;
    --f-black: 900;
    --shadow-primary: 0px 4px 10px rgba(37, 47, 63, 0.1);
    --shadow-light: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    --shadow-dark: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    --shadow-1: 0px 6px 34px rgba(215, 216, 222, 0.41);
    --shadow-slide-item: 0px 0 10px 0 rgba(215, 216, 222, 0.20);
    --shadow-2: 0 15px 34px 0 rgba(175, 181, 204, 0.32);
    --shadow-3: 0 5px 15px 0 rgba(85, 74, 101, 0.37);
    --shadow-5: 0px 20px 70px 0px rgba(0, 0, 0, 0.1);
    --shadow-7: 0px 15px 30px -2px rgba(0, 0, 0, 0.1);
    --shadow-8: -5px 18px 60px 0 rgba(210, 210, 234, 0.4);
    --shadow-9: 0 13px 48px 0 rgba(215, 216, 222, 0.44);
    --shadow-10: 0 13px 14px 0 rgba(129, 104, 145, 0.05);
    --shadow-11: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    --transition: 0.3s;
    --transition-2: 256ms ease-in-out;
    --transition-3: transform .85s cubic-bezier(.23,1,.32,1),background .3s,border .3s,border-radius .3s,box-shadow .3s;
    --transition-4: all .25s cubic-bezier(.645,.045,.355,1);
    --font-primary: "Euclid Circular";
    --font-secondary: "Euclid Circular";
    --font-alt: "Source Serif Pro", serif;
    --font-size-b1: 24px;
    --font-size-b2: 18px;
    --font-size-b3: 16px;
    --font-size-b4: 12px;
    --line-height-b1: 1.5;
    --line-height-b2: 1.67;
    --line-height-b3: 1.67;
    --line-height-b4: 1;
    --h1: 52px;
    --h2: 44px;
    --h3: 34px;
    --h4: 30px;
    --h5: 20px;
    --h6: 16px;
    --div-gap-1: 10px;
    --gradient-1: linear-gradient(rgb(255, 255, 255) 0%, rgb(239, 241, 255) 100%);
    --gradient-4: linear-gradient(180deg,#fff,#f6f9ff);
    --gradient-2: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(240, 242, 255) 100%);
    --gradient-3: linear-gradient(90deg, #8DA9FC 0%, #B48DD5 100%);
    --gradient-4: linear-gradient(180deg, #EFF1FF 0%, #FFFFFF 100%);
    --gradient-5: linear-gradient(rgb(239, 241, 255) 0%, rgb(239, 241, 255) 100%);
    --gradient-6: linear-gradient(to right, rgba(255, 255, 255, 0.15), rgba(251, 199, 226, 0.15), rgba(220, 217, 254, 0.15));
    --gradient-7: linear-gradient(218.15deg, var(--color-secondary) 0%, var(--color-primary) 100%);
    --gradient-8: linear-gradient(270deg, #9e77ff 0%, #4460ff 100%);
    --gradient-9: linear-gradient(180deg, #6827FF 0%, #E3F0FF 100%);
}

/* section.my-5.py-5 {
    display: none;
} */
/* @media (max-width:1500px) {
    html {
        font-size: 80%;
    }
} */

#serv_1 .team-img{
    width: calc(113% - 2rem);
    height: calc(100% - 2rem);
    border-radius: 100%;
    margin: auto;
}
body {
    font-family: 'Roboto', sans-serif;
    background: #fff;
    font-weight: 400;
    font-size: 1rem;
    overflow-x: hidden;
}

.navbar.active {
    background-color: #fff;
}
.navbar-light .navbar-nav .nav-link{
    color: var(--main-color);
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--main-color)
}

a {
    color: var(--link-color);
}

.shadow-th {
    box-shadow: 0px 3px 40px #00000029;
}

.btn-blue {
    background-color: var(--orange-color);
    color: #fff;
}

.btn-blue:hover {
    background-color: var(--yellow-color);
    color: #fff;
}

.btn-yellow {
    background: #1B1363 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 15px #00000033;
border-radius: 10px!important;
}

.btn-yellow:hover {
    background-color: var(--orange-color);
}

.fw-medium {
    font-weight: 500;
}

.lh-35 {
    line-height: 35px;
}

.lh-40 {
    line-height: 40px;
}

.lh-19 {
    line-height: 19px;
}

.lh-23 {
    line-height: 23px;
}
.fs-4 {
    font-size: 1.4rem!important;
}
p.col-md-auto.mb-0.text-orange.mt-3.mt-md-2{
    color: #111113;
}
.text-orange {
    color: var(--orange-color)
}
.text-oranges {
    color: var(--orange-colors)
}

.text-main {
    color: var(--main-color) !important;
}

.text-second {
    color: var(--second-color);
}

.text-yellow {
    color: var(--yellow-color);
}
.text-gray {
    color: var(--gray-color);
}

.bg-red {
    background-color: var(--red-color);
}

.bg-gray {
    background-color: var(--gray-color);
}

.bg-yellow {
    background-color: var(--yellow-color);
}

.bg-purple {
    background-color: var(--link-color);
}

.owl-theme .owl-dots .owl-dot.active span {
    background: #f7b654;
    width: 35px;
}
.feature-owl .owl-dots .owl-dot.active span {
    background: var(--bs-orange);
    width: 35px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #1b1363!important;
}
.owl-theme .owl-dots .owl-dot span {
    width: 14px;
    height: 14px;
    margin: 5px 5px;
    background: var(--gray-color);
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
}
.owl-carousel .owl-nav button {
    background: #fff!important;
    border-radius: 100px !important;
    box-shadow: 0px 3px 40px #00000029;
    width: 55px;
    height: 55px;
}
.owl-theme .owl-nav [class*='owl-']:hover {
    background: #869791;
    color: var(--yellow-color);
    text-decoration: none;
}
.owl-nav {
    position: absolute;
    margin: 0 auto !important;
    left: 0;
    right: 0;
    width: 40%;
    bottom: 45%;
}

@media (max-width:600px){
    .owl-nav {
        bottom: 40%;
    }
}
button.owl-next {
    float: right;
}
button.owl-prev {
    float: left;
}
.copyright-area.copyright-style-1.ptb--20 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px 2px #00000029;
    margin-top: 5px;
}
footer.containers.task-footer.footer-style-1.bg-color-white.overflow-hidden{
    background: #FFFFFF 0% 0% no-repeat padding-box;

}
#section-footer {
    padding-top: 60px;
}